import React from "react";
import Page from "../components/Page";

const AboutPage = ({ slug }) => {
	return (
		<Page>
			<h1>About.me</h1>
		</Page>
	);
};

export default AboutPage;
