import React from "react";
import Page from "../components/Page";

const ProjectsPage = () => {
	return (
		<Page>
			<h1>Projects</h1>
		</Page>
	);
};

export default ProjectsPage;
